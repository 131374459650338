import { useMemo } from 'react';

export default function ShuffleQuestion({ options, selectedAnswer, answerSelectionHandler }) {
    const shuffledOptions = useMemo(() => { return [...options].sort(() => Math.random() - 0.5) }, [options]);
    const selectedOptions = selectedAnswer || {};

    return (
        <div className="container grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-7 my-6 lg:my-8">
            {shuffledOptions.map((option) => (
                <div key={option.id} className={`border border-purple p-3 rounded-[3px] cursor-pointer ${selectedOptions.hasOwnProperty(option.id) ? 'bg-purple text-white' : 'text-purple'}`} onClick={() => answerSelectionHandler(option)}>
                    {option.text}
                </div>
            ))}
        </div>
    )
}