import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ProductLink from './ProductLink';
import { Helmet } from "react-helmet";
export default function OptionQuestion({ options, selectedAnswer, answerSelectionHandler, detail_text }) {

  const scrollToAnchor = () => {
    setTimeout(() => {
      const target = document.querySelector("#anchor");
      target && target.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const handleClick = (option) => {
    answerSelectionHandler(option);
    scrollToAnchor();
  };

  const isSVG = (url) => {
    return url.endsWith(".svg");
  }

  useEffect(() => {
    scrollToAnchor();
  }, [options]);

  return <>
    <Helmet>
      <title>What Cabinet Storage is Right for You? – QUIZ | KraftMaid</title>
      <meta name="description" content="Take a short four question cabinet storage quiz to help you discover specialized storage solutions that fit the way you live." />
      <meta name="robots" content="noindex" />
      <link rel="canonical" href="https://www.kraftmaid.com/cabinet-storage-quiz/" />
    </Helmet>

    <div className="container">
      <div className={`flex flex-col lg:grid gap-4 lg:gap-7 my-6 lg:my-8 ${options.length <= 4 ? 'lg:grid-cols-4' : 'lg:grid-cols-5'}`}>
        {options.map((option, index) => (
          <button key={index} onClick={() => handleClick(option)} className={`h-full border border-purple p-2 flex text-left w-full group ${option.introduction === selectedAnswer?.introduction ? 'border-4' : ''}`}>
            <div className="grid grid-cols-1 grid-cols-8 gap-2 w-full">
              <div className="col-span-3 lg:col-span-12 lg:mb-2 overflow-hidden">

                <img
                  src={option.img}
                  alt={`${option.name}`}
                  className={`lg:w-full lg:max-w-full lg:max-h-48 lg:min-h-48 transition-transform duration-[1000ms] ease-out  ${options[index].slug == 'corner-storage' || options[index].slug == 'compact-kitchen' ? '' : 'group-hover:scale-[1.3]'}  ${!isSVG(option.img) ? 'object-cover' : ''}`}
                />

              </div>
              <div className="col-span-5 pl-2 lg:pl-0 lg:col-span-12 flex items-center">{option.introduction}</div>
            </div>
          </button>
        ))}
      </div>
    </div>

    {selectedAnswer && (
      <div id="anchor" className='bg-light-gray pt-3 lg:pt-12 pb-7 lg:pb-12 grid gap-4 grow'>
        <div className='container contents lg:grid lg:grid-cols-12 lg:gap-7'>
          {/* <img src={selectedAnswer.img} alt={`${selectedAnswer.name} Photo.`} className='container lg:w-full max-w-120 lg:max-w-full lg:self-stretch object-cover' /> */}

          <div className="container mx-auto w-[90%] lg:w-[100%] col-span-7 lg:col-span-4">
            {/* <h3 className='lg:mb-0.5'>{detail_text}</h3> */}
            <h2 className='h1 lg:mb-5'>{selectedAnswer.name}</h2>
            <p>{selectedAnswer.description}</p>
          </div>

          <div className="flex flex-col col-span-8 gap-4 lg:justify-between">
            <div className='relative'>
              <Swiper
                //   slidesPerView={'auto'}
                //   spaceBetween={16}
                className="mySwiper"
                navigation={true}
                breakpoints={{
                  0: {
                    slidesPerView: 'auto',
                    spaceBetween: 16,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 28,
                  },
                }}
                modules={[Navigation]}
              >
                {selectedAnswer.products && selectedAnswer.products.map(product => (
                  <SwiperSlide key={product.name} className='w-[196px] lg:w-full mobile-full-width'>
                    <ProductLink product={product} containerClass="inline-block max-w-[196px] lg:max-w-none" imageClass='w-[196px] lg:w-full aspect-square object-cover' />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
}