import React, { useCallback, useState } from 'react';
import KMLogo from './images/KMlogo.png';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { QuizContext } from './store';
import { useContext, useEffect } from 'react';

export default function PageLayout(props) {
    const _Data = props;
    const { initializeState, isStateInitialized } = useContext(QuizContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [isShow, setIsShow] = useState(false);

    const isPath = useCallback(() => {
        const path = location?.pathname;
        const arr = ['/question1', '/question2', '/question3', '/question4'];
        const res = arr.includes(path);
        setIsShow(res);
    }, [location?.pathname]);

    useEffect(() => {
        if (!isStateInitialized) {
            initializeState(_Data.totalSteps)
        }
        isPath();
    }, [isPath, initializeState, isStateInitialized, _Data.totalSteps])

    return (
        <div className="max-w-screen min-h-screen grid grid-rows-[auto_1fr]">
            <header className="pt-8 pb-6 lg:py-6 border-b border-b-gray">
                <img src={KMLogo} alt="Kraftmaid Logo." className="cursor-pointer mx-auto w-[48%] max-w-[312px]" onClick={() => navigate('/')} />
            </header>
            <main className='pt-6 lg:pt-12'>
                {isShow &&
                    <div className='container flex flex-col gap-8 lg:gap-8'>
                        <div className='text-center'>
                            <h1 className='font-campton-medium text-[13px] lg:text-[15px] leading-5 uppercase font-black mb-4'>{_Data.quiz_title}</h1>
                        </div>
                    </div>
                }
                <Outlet />
            </main>
        </div>
    )
}

