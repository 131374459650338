import React from 'react';
import { useParams } from "react-router-dom";
import ProductLink from "./ProductLink";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useState } from "react";
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { Helmet } from "react-helmet";

const notificationTarget = {
    CLIPBOARD_BUTTON: 'CLIPBOARD_BUTTON',
    DOWNLOAD_BUTTON: 'DOWNLOAD_BUTTON',
}
export default function Result({ style, data }) {
    const { storageIndicators } = useParams();
    const storageSlugs = storageIndicators.split('_');
    const currentUrl = window.location.href;
    const [displayNotification, setDisplayNotification] = useState(undefined);

    const savePageToPdf = async () => {

        setDisplayNotification(notificationTarget.DOWNLOAD_BUTTON);

        const page = document.getElementById('root');
        const duplicatedNode = page.cloneNode(true);
        const isLargeViewport = page.clientWidth >= 1024;

        const styleIntro = duplicatedNode.querySelector('.storage-style-intro-section');
        styleIntro.style['display'] = 'flex';
        styleIntro.style['flex-direction'] = 'column';

        const mustHaveContainer = duplicatedNode.querySelector('.must-have-container');

        if (!isLargeViewport) {
            const introChildren = [...styleIntro.children];

            for (let [index, child] of introChildren.entries()) {
                if (child.tagName === 'IMG') {
                    child.style['width'] = '92%'
                    child.style['margin'] = '0 auto 20px';
                    child.style['order'] = '0';
                } else {
                    child.style['order'] = `${index + 1}`;
                }
            }

            const introParagraph = styleIntro.querySelector('p');
            introParagraph.style['border-bottom-width'] = '0'

            const headings = duplicatedNode.querySelectorAll('h2');

            for (let heading of headings) {
                heading.style['font-size'] = '28px';
                heading.style['line-height'] = '32px';
                heading.style['margin-bottom'] = '12px';
            }

            mustHaveContainer.style['padding-top'] = '12px';

            const footer = duplicatedNode.querySelector('.result-footer');
            footer.style['padding-bottom'] = '0'

        } else {
            mustHaveContainer.style['grid-template-columns'] = 'repeat(2, minmax(0, 1fr))';
            mustHaveContainer.style['grid-template-rows'] = 'repeat(2, minmax(0, 1fr))';
        }

        const headings = duplicatedNode.querySelectorAll('h3');

        for (let heading of headings) {
            heading.style['font-size'] = '18px';
            heading.style['line-height'] = '24px';
            heading.style['margin-bottom'] = '12px';
        }

        const pageInitiators = duplicatedNode.querySelectorAll('[style="break-before: page;"]');

        for (let section of pageInitiators) {
            section.style['padding-top'] = '40px';
        }

        const buttons = duplicatedNode.querySelectorAll('.button');

        for (let button of buttons) {
            const textNode = button.firstElementChild;
            textNode.style['position'] = 'relative';
            textNode.style['top'] = '-8px';
        }

        const swiperSliders = duplicatedNode.querySelectorAll('.swiper-wrapper');

        for (let slider of swiperSliders) {
            slider.style['flex-wrap'] = 'wrap';
            slider.style['transform'] = 'none';
            if (!isLargeViewport) {
                slider.style['width'] = '92%';
                slider.style['margin'] = '0 auto';
            }
        }

        const swiperSlides = duplicatedNode.querySelectorAll('.swiper-slide');

        for (let [index, slide] of swiperSlides.entries()) {
            slide.style['width'] = 'calc(50% - 20px)';
            slide.style['margin-right'] = index % 2 === 0 ? '40px' : '0';
            slide.style['margin-bottom'] = '40px';
            slide.style['padding'] = '0';
            slide.style['transform'] = 'none';

            const imageContainer = slide.firstElementChild
            imageContainer.style['max-width'] = '100%';

            const image = slide.firstElementChild.firstElementChild;
            image.style['width'] = '100%';
        }

        const save = await html2pdf().set({
            image: {
                type: 'jpeg',
                quality: isLargeViewport ? 1 : 0.95
            },
            html2canvas: {
                windowWidth: isLargeViewport ? 1600 : page.clientWidth
            },
            jsPDF: {
                compress: true
            },
            pagebreak: {
                mode: 'css',
            }
        }).from(duplicatedNode).save(`${style.title} Style.pdf`);


        setDisplayNotification(undefined);

    }

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        element.scrollIntoView({ behavior: "smooth" });
    }
    const showClipboardNotification = () => {
        setDisplayNotification(notificationTarget.CLIPBOARD_BUTTON);
        setTimeout(() => setDisplayNotification(undefined), 1000)
    }

    const copyCurrentLocation = async (e) => {
        e.preventDefault()

        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(currentUrl);
            showClipboardNotification();
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = currentUrl;
            textArea.style.position = 'fixed';
            textArea.style.top = '100%';
            textArea.style.right = '100%';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const success = document.execCommand('copy');
                if (success) {
                    showClipboardNotification();
                }
            } catch (err) {
                console.error(err.name, err.message);
            }
            document.body.removeChild(textArea);
        }

    }

    return (
        <>
            <Helmet>
                <title>What Cabinet Storage is Right for You? – QUIZ | KraftMaid</title>
                <meta name="description" content="Take a short four question cabinet storage quiz to help you discover specialized storage solutions that fit the way you live." />
                <meta name="robots" content="noindex" />
                <link rel="canonical" href="https://www.kraftmaid.com/cabinet-storage-quiz/" />
            </Helmet>
            <div className="contents lg:block container mt-9">
                <div className="lg:grid lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-x-7 lg:gap-y-4 lg:border-b lg:border-gray lg:pb-8 storage-style-intro-section">
                    <div className="container pb-4 lg:order-2 lg:w-full lg:p-0">
                        <h3>{data.storage_style_text}</h3>
                        <h1>{style.title}</h1>
                    </div>
                    <img src={style.img} alt={`${style.title}`} className='w-full lg:order-1 lg:row-span-2' />
                    <div className="container pt-2 pb-6 lg:w-full lg:p-0 lg:order-3">
                        <p className="pb-4 border-b border-gray lg:border-none lg:pb-7">
                            {style.description}
                        </p>
                        <button onClick={() => scrollToElement('recap')} className="hidden lg:inline-block button text-center !w-auto hover:bg-black border border-white"><span>view suggested storage solutions</span></button>

                    </div>
                </div>
            </div>
            <div className="container text-left lg:pt-8" style={{ 'page-break-before': 'always' }} id="recap">
                <h2 className="mb-2 lg:mb-4">{data.recap_intro}</h2>
                <p>{data.recap_text}</p>
            </div>
            <div className="py-6 lg:pt-12 lg:pb-[72px] flex flex-col gap-9 lg:gap-8">
                {storageSlugs.map((slug, index) => {
                    const storageObject = data.questions[index].options.find(option => option.slug === slug);
                    const { products, name } = storageObject;
                    return <div className="result-recap-storage-container" style={index !== 0 ? { 'page-break-before': 'always' } : {}} key={slug}>
                        <h3 className="container pb-1.5 lg:pb-2">{name}</h3>
                        <div className="contents lg:block container ">
                            <div className='relative'>
                                <Swiper
                                    //   slidesPerView={'auto'}
                                    //   spaceBetween={16}
                                    className="mySwiper"
                                    navigation={true}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 'auto',
                                            spaceBetween: 16,
                                        },
                                        1024: {
                                            slidesPerView: 4,
                                            spaceBetween: 28,
                                        },
                                    }}
                                    modules={[Navigation]}
                                >
                                    {products.map(product => (
                                        <SwiperSlide key={product.name} className='w-[196px] lg:w-full mobile-full-width'>
                                            <ProductLink isResult={true} product={product} containerClass="inline-block max-w-[196px] lg:max-w-none" imageClass='w-[196px] lg:w-full aspect-square object-cover' />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                })}
            </div>
            <div className="bg-light-gray pt-4 pb-8 lg:pt-8 lg:pb-10 dont-forget-container must-have-container" style={{ 'page-break-before': 'always' }}>
                <div className="container">
                    <h2 className="text-center mb-4 lg:mb-6">{data.must_have_text}</h2>
                    <div className="grid grid-cols-2 gap-y-7 gap-x-4 lg:grid-cols-4 lg:gap-x-7 mb-36 lg:mb-32">
                        {data.must_have.map(mustHave => <ProductLink key={mustHave.name} isResult={true} product={mustHave} imageClass="w-[196px] w-full aspect-square object-cover" />)}
                    </div>
                </div>
            </div>
            <div className="bg-white w-full fixed bottom-0 grid grid-cols-2 gap-4 place-content-stretch lg:flex justify-center py-6 lg:py-7 px-8 z-[20] flex-col lg:flex-row items-center result-footer">
                <button className="relative button h-12 lg:w-48 lg:h-auto lg:wider-button overflow-hidden hover:bg-black border border-white flex items-center	justify-center" onClick={savePageToPdf}>
                    <span>Save results</span>
                    <span className={`absolute top-0 left-0 bg-copper pt-[14px] pb-[10px] text-center h-full inline-block w-full transition-all duration-500 ${displayNotification === notificationTarget.DOWNLOAD_BUTTON ? 'translate-y-0' : 'translate-y-full'}`}>Downloading...</span>
                </button>
                <a href={currentUrl} onClick={copyCurrentLocation} className="relative button h-12 lg:w-48 lg:h-auto lg:wider-button secondary-button text-center overflow-hidden hover:bg-black hover:text-white border border-white flex items-center	justify-center">
                    <span className='leading-5 lg:leading-3'>Share results</span>
                    <span className={`absolute top-0 left-0 bg-white pt-[14px] pb-[10px] text-center h-full inline-block w-full transition-all duration-500 ${displayNotification === notificationTarget.CLIPBOARD_BUTTON ? 'translate-y-0' : 'translate-y-full'}`}>Copied to clipboard</span>
                </a>
                <a href="https://www.kraftmaid.com/where-to-buy/" target="_blank" className="button h-12 lg:w-48 lg:h-auto lg:wider-button secondary-button text-center hover:bg-black hover:text-white border border-white flex items-center	justify-center"><span>Find a showroom</span></a>
                <a href="/" className="button h-12 lg:w-48 lg:h-auto lg:wider-button secondary-button text-center hover:bg-black hover:text-white border border-white flex items-center	justify-center"><span>Start Over</span></a>
            </div>
        </>
    )
}