import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { QuizContext } from './store';

import KMLogo from './images/KMlogo.png';

const HomePage = (props) => {
    const _Data = props;
    const navigate = useNavigate();

    const { retrieveObjectFromStorage } = useContext(QuizContext);
    const [currentStep, setCurrentStep] = useState(null);

    useEffect(() => {
        const cStep = retrieveObjectFromStorage()
        cStep ? setCurrentStep(cStep?.currentStep + 1) : setCurrentStep(1);
    }, [retrieveObjectFromStorage]);

    return (
        <>
            <Helmet>
                <title>What Cabinet Storage is Right for You? – QUIZ | KraftMaid</title>
                <meta name="description" content="Take a short four question cabinet storage quiz to help you discover specialized storage solutions that fit the way you live." />
            </Helmet>
            <div className="max-w-screen min-h-screen grid grid-rows-[auto_1fr]">
                <header className="pt-8 pb-6 lg:py-6 border-b border-b-gray">
                    <img src={KMLogo} alt="Kraftmaid Logo." className="cursor-pointer mx-auto w-[48%] max-w-[312px]" onClick={() => navigate('/')} />
                </header>
                <main className='pt-6 lg:pt-12'>
                    <section className='h-full flex flex-col'>
                        <div className='container flex flex-col gap-8 lg:gap-8 mb-10'>
                            <div className='text-center'>
                                <h1 className='font-campton-medium text-[13px] lg:text-[15px] leading-5 uppercase font-black mb-4'>{_Data.quiz_title}</h1>
                                <h2 className='h1 mb-4'>{_Data.page_title}</h2>
                                <p className='mb-4'>{_Data.headline}</p>
                                <button className='button hover:bg-black border border-white' onClick={() => navigate(`/question${currentStep}`)}>{currentStep > 1 ? _Data.cta_label_continue : _Data.cta_label_start}</button>
                            </div>
                        </div>

                        {/* <div className='container grid grid-cols-12' style={{ maxWidth: '995px' }}> */}
                        <div className='container flex  grid-cols-12'>
                            <div className='col-span-10 mx-auto'>
                                <div className="hidden lg:block">
                                    <img src={_Data.images[0].desktop} alt="Kraftmaid" className='object-cover w-full h-full' />
                                </div>
                                <div className="block lg:hidden">
                                    <img src={_Data.images[0].mobile} alt="Kraftmaid" className='object-cover w-full h-full' />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </>
    )
}
export default HomePage