import { createContext, useReducer } from "react";
import { useNavigate } from 'react-router-dom';

const initialState = {
    totalSteps: 4,
    currentStep: 1,
    answers: {},
    isStateInitialized: false
}

export const QuizContext = createContext(initialState);

const STORAGE_OBJECT_NAME = "KM_Storage_Quiz_Data"

function saveObjectInStorage(object){
    sessionStorage.setItem(STORAGE_OBJECT_NAME, JSON.stringify(object))
}

function cleanStorage(){
    sessionStorage.removeItem(STORAGE_OBJECT_NAME);
}

function retrieveObjectFromStorage(){
    const sessionStorageString = sessionStorage.getItem(STORAGE_OBJECT_NAME);
    const sessionStorageObject = JSON.parse(sessionStorageString);

    return sessionStorageObject
}

const DISPATCH_ACTIONS = {
    SAVE_ANSWER: "SAVE_ANSWER",
    UPDATE_STEP: "UPDATE_STEP",
    SET_INITIAL_STATE: "SET_INITIAL_STATE"
}

const quizDataReducer = (state, action) => {
    const {payload, type} = action;

    switch(type){
        case DISPATCH_ACTIONS.SAVE_ANSWER:
            const updatedAnswers = {...state.answers};
            updatedAnswers[state.currentStep] = payload;
            const updatedState = {
                ...state,
                answers: updatedAnswers
            }
            saveObjectInStorage(updatedState)
            return updatedState
        case DISPATCH_ACTIONS.UPDATE_STEP:
            return {
                ...state,
                currentStep: payload
            }
        case DISPATCH_ACTIONS.SET_INITIAL_STATE:
            const previousSessionState = retrieveObjectFromStorage();
            return previousSessionState ?? {...initialState, isStateInitialized: true, totalSteps: payload};
    }
}

export default function QuizProvider({children}){
    const [quizData, dispatch] = useReducer(quizDataReducer,initialState);
    const navigate = useNavigate();

    const moveOneStep = (next = true)=>{
        const currentStep = quizData.currentStep;
        const targetStep = currentStep  + (next ? 1 : -1);

        // Prevents next if button disable fails 
        if(next && !quizData.answers[currentStep]){
            return;
        }

        moveQuizStep(targetStep);
    }

    const moveQuizStep = (targetStep, navigateRouter = true)=>{
        const isTargetNextAnswerableStep = quizData.answers[targetStep-1] || targetStep === 1;
        if(!isTargetNextAnswerableStep){
            if(!navigateRouter) navigate(`/question${quizData.currentStep}`)
            return;
        }

        dispatch({
            type: DISPATCH_ACTIONS.UPDATE_STEP,
            payload: targetStep
        });

        if(navigateRouter){
            navigate(`/question${targetStep}`);
        }
    }

    const navigateToResults = ()=>{
        const {answers, totalSteps} = quizData;
        const answerKeys= Object.keys(answers);
        if(answerKeys.length === totalSteps){
            const styleSelectionCount = {};
            const selectedStyles = Object.values(answers[totalSteps]).map(object => object.style)
            selectedStyles.forEach(style => styleSelectionCount[style] ? styleSelectionCount[style] += 1 : styleSelectionCount[style] = 1);
            const mostSelectedStyle = Object.keys(styleSelectionCount).sort((a, b) => styleSelectionCount[b] - styleSelectionCount[a])[0];
            const mostSelectedStyleSlug = mostSelectedStyle.toLowerCase().replace(' ','-');
            const storageSlugsArray = answerKeys.slice(0, -1).map(storageAnswerKey => answers[storageAnswerKey].slug);
            const storagesPath = storageSlugsArray.join('_');
            navigate(`/results/${mostSelectedStyleSlug}/${storagesPath}`);
            cleanStorage();
        }
    }

    const initializeState = (totalSteps)=>{
        dispatch({
            type: DISPATCH_ACTIONS.SET_INITIAL_STATE,
            payload: totalSteps
        });
    }

    const setOptionAnswer = (selectedObject)=>{
        dispatch({
            type: DISPATCH_ACTIONS.SAVE_ANSWER,
            payload: selectedObject
        });
    }
    const setShuffleAnswer = (selectedObject)=>{
        const currentStepAnswers = quizData.answers[quizData.currentStep];
        const updatedObject = {...currentStepAnswers};
        if(updatedObject.hasOwnProperty(selectedObject.id)){
            delete updatedObject[selectedObject.id]
        } else if(Object.keys(updatedObject).length < 5) {
            updatedObject[selectedObject.id] = selectedObject;
        } else {
            return;
        }
        dispatch({
            type: DISPATCH_ACTIONS.SAVE_ANSWER,
            payload: updatedObject
        });
    }

    return(
        <QuizContext.Provider value={{
            quizData,
            retrieveObjectFromStorage,
            moveQuizNextStep: ()=>moveOneStep(),
            moveQuizPrevStep: ()=>moveOneStep(false),
            moveQuizStep,
            setAnswer: {
                option: setOptionAnswer,
                shuffle: setShuffleAnswer,
            },
            navigateToResults,
            initializeState,
            isStateInitialized: quizData.isStateInitialized
        }}>
            {children}
        </QuizContext.Provider>
    )
}
