import React from 'react';
import PageLayout from './PageLayout';
import { Route, Routes } from 'react-router-dom';
import QuizProvider from './store';
import QuestionPage from './QuestionPage';
import Result from './Result';
import * as data from './Data.json';
import HomePage from './HomePage';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-N2L4TSG7'
}

TagManager.initialize(tagManagerArgs)

//scrolling to the top of the page for each route change
function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { questions, storage_styles, questions_headline, quiz_title, home_page } = data;
  useScrollToTop()
  return (
    <QuizProvider>
      <Routes>
        <Route path="/" element={<HomePage {...home_page[0]} />} />
        <Route element={<PageLayout {...home_page[0]} totalSteps={questions.length} />}>
          {questions.map((question, index) => {
            const currentQuestion = index + 1;
            return (
              <Route
                key={index}
                path={`/question${currentQuestion}`}
                mount={window.scrollTo(0, 0)}
                element={
                  <QuestionPage
                    question={question}
                    introText={questions_headline}
                    title={quiz_title}
                  />
                }
              />
            );
          })}
          {storage_styles.map((style) => (
            <Route
              key={style.id}
              path={`/results/${style.slug}/:storageIndicators`}
              element={<Result style={style} data={data} />}
            />
          ))}
        </Route>
      </Routes>
    </QuizProvider>
  );
}

export default App;