// QuestionPage.js
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QuizContext } from './store';
import OptionQuestion from './OptionQuestion';
import ShuffleQuestion from './ShuffleQuestion';

/**
 * Renders a question page component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.question - The question to be displayed.
 * @returns {JSX.Element} The question page component.
 */

const questionTypeComponents = {
  option: OptionQuestion,
  shuffle: ShuffleQuestion
}


function QuestionPage({ question, introText, title }) {
  const { question_text, detail_text, options } = question;
  const { quizData, moveQuizNextStep, moveQuizPrevStep, setAnswer, moveQuizStep, isStateInitialized, navigateToResults } = useContext(QuizContext);
  const { totalSteps, currentStep, answers } = quizData;

  const location = useLocation();

  useEffect(() => {
    if (isStateInitialized) {
      const loadedStep = +(location.pathname.replace('/question', ''));
      const targetStep = answeredQuestions < totalSteps ? answeredQuestions + 1 : totalSteps;
      if (loadedStep !== targetStep || loadedStep !== currentStep) {
        moveQuizStep(targetStep)
      }
    }
  }, [isStateInitialized])

  useEffect(() => {
    if (isStateInitialized) {
      const loadedStep = +(location.pathname.replace('/question', ''));
      if (loadedStep !== currentStep) {
        moveQuizStep(+loadedStep, false)
      }
    }
  }, [location])

  function answerSelectionHandler(answer) {
    setAnswer[question.type](answer);
  }

  const selectedAnswer = answers[currentStep];

  const answeredQuestions = Object.keys(answers).length;

  const Component = questionTypeComponents[question.type];

  return (
    <section className='h-full flex flex-col'>
      <div className='container flex flex-col gap-8 lg:gap-8'>
        <div className="mx-auto w-fit flex justify-center items-center lg:mb-4 relative before:content-[''] before:absolute before:top-[50%] before:w-full before:h-[1px] before:bg-purple gap-6 before:z-[-1]">
          {Array.apply(null, Array(totalSteps)).map((item, index) => (
            <button key={index} disabled={(index) > answeredQuestions} onClick={() => moveQuizStep(index + 1)} className={`border border-purple h-7 w-7 flex justify-center items-center rounded-full ${index < currentStep ? 'bg-purple' : 'bg-white'}`}><span className={`inline pt-1 text-xs leading-5 ${index < currentStep ? 'text-white' : 'text-purple'}`}>{index + 1}</span></button>
          ))}
        </div>
        <h2 className='text-center h1'>{question_text}</h2>
      </div>
      <div className='pb-[86px] lg:pb-[94px] grow flex flex-col'>
        {isStateInitialized && <Component options={options} selectedAnswer={selectedAnswer} answerSelectionHandler={answerSelectionHandler} detail_text={detail_text} />}
      </div>
      <div className='bg-white w-full fixed bottom-0 flex gap-4 justify-center py-6 lg:py-7 z-[20]'>
        {currentStep !== 1 && <button onClick={moveQuizPrevStep} className='button secondary-button border border-white hover:text-white hover:bg-black'>Go Back</button>}
        {currentStep !== totalSteps && <button onClick={moveQuizNextStep} disabled={!selectedAnswer} className={`button ${selectedAnswer && 'hover:bg-black border border-white'}`}>Next Question</button>}
        {currentStep === totalSteps && <button onClick={navigateToResults} disabled={!selectedAnswer} className={`button hover:bg-black border border-white`}>Results</button>}
      </div>
    </section>
  );
}

export default QuestionPage;